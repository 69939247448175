import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div class="ww-list-header">
      {/* <div class="resources-list-header-title">OUR TEAM</div>
      <div class="resources-list-header-subtitle">
      Meet our executive team- all of them being active members in bringing all ML/AI enthusiasts under the roof of Kharagpur Data Analytics Group!
      </div> */}
    </div>
  );
};

export default Header;
