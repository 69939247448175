import 'antd/lib/tabs/style/index.css';
import "./App.css";
// import 'antd/dist/antd.css';
import AppRouter from "./Router/AppRouter";

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
