const members_2022 = [
	{
		name: "Swapnil Agarwal",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Swapnil Agrawal.jpeg",
		linkedin: "https://www.linkedin.com/in/swapnilagrawal1/",
		degree: "Mechanical Engineering",
		graduation_year: 2022,
		workplace: "Wells Fargo",
		designation: "",
		additional_info: "",
	},
	{
		name: "Sanchit Agarwal",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Sanchit A.jpeg",
		linkedin: "https://www.linkedin.com/in/sanchit-a-98a9b8ab/",
		degree: "Electrical Engineering",
		graduation_year: 2022,
		workplace: "Societe Generale Global Solution Centre",
		designation: "",
		additional_info: "",
	},
	{
		name: "Ananya Sharma",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Ananya Sharma.jpeg",
		linkedin: "https://www.linkedin.com/in/ananyasharmaiitkgp/",
		degree: "Industrial and Systems Engineering",
		graduation_year: 2022,
		workplace: "Nomura",
		designation: "",
		additional_info: "",
	},
	{
		name: "Vasu Gurram",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Vasu Gurram.jpeg",
		linkedin: "https://www.linkedin.com/in/vasu-gurram-a94687177/",
		degree: "Computer Science Engineering",
		graduation_year: 2022,
		workplace: "Sprinklr",
		designation: "",
		additional_info: "",
	},
	{
		name: "Bhavik Jain",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Bhavik Jain.jpeg",
		linkedin: "https://www.linkedin.com/in/bhavik-jain-142545170/",
		degree: "Metallurgical and Materials Engineering",
		graduation_year: 2022,
		workplace: "Accenture",
		designation: "",
		additional_info: "",
	},
	{
		name: "Lokesh Chikkula",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Lokesh Chikkula.jpeg",
		linkedin: "https://www.linkedin.com/in/lokesh-chikkula-962301186/",
		degree: "Computer Science Engineering",
		graduation_year: 2022,
		workplace: "Mastercard",
		designation: "",
		additional_info: "",
	},
	{
		name: "Tista Kundu",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Tista Kundu.jpeg",
		linkedin: "https://www.linkedin.com/in/tista-kundu-4433b7172/",
		degree: "Electronics and Electrical Communication Engineering",
		graduation_year: 2022,
		workplace: "Qualcomm",
		designation: "",
		additional_info: "",
	},
	{
		name: "Apoorv Singh",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Apoorv Singh.jpeg",
		linkedin: "https://www.linkedin.com/in/apoorvsingh1610/",
		degree: "Chemical Engineering",
		graduation_year: 2022,
		workplace: "Axis Bank",
		designation: "",
		additional_info: "",
	},
	{
		name: "Aditi Singhania",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Aditi Singhania.jpeg",
		linkedin: "https://www.linkedin.com/in/aditi-singhania-436371146/",
		degree: "Electrical Engineering",
		graduation_year: 2022,
		workplace: "Adobe",
		designation: "",
		additional_info: "",
	},
	{
		name: "Shreya Kumari",
		image:"images/Alumni_All_Images/Graduating Batch of 2022/Shreya Kumari.jpeg",
		linkedin: "https://www.linkedin.com/in/shreya-kumari-bb1517176/",
		degree: "Industrial and Systems Engineering",
		graduation_year: 2022,
		workplace: "J.P Morgan",
		designation: "",
		additional_info: "",
	},
	{
		name: "Arghyadeep Bandopadhyay",
		image:"images/Alumni_All_Images/generic profile avatar.png",
		linkedin: "https://www.linkedin.com/in/arghyadeep-bandyopadhyay-672b881a9/",
		degree: "Electrical Engineering",
		graduation_year: 2022,
		workplace: "Samsung R&D Institute",
		designation: "",
		additional_info: "",
	},
];

export default members_2022;
